import React, { Component } from "react";
import emailjs from 'emailjs-com';
import { Modal, Button } from 'react-bootstrap';


class ContactThree extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
            showModal: false,
        };
    };

    handleSubmit = (event) => {
        event.preventDefault();

    const { rnName, rnEmail, rnSubject, rnMessage } = this.state;
    emailjs
      .send("service_g4jhpsh", "template_shbflvs", {
        rnName,
        rnEmail,
        rnSubject,
        rnMessage,
      }, "9p_u_XvAvyHpn-eh7")
      .then(
        (result) => {
          console.log(result.text);
          this.setState({ showModal: true });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                {/* <p className="description">I am available for freelance work. Connect with me via phone: <a href="tel:+8801923088574">01923 088574</a> or email:
                                    <a href="mailto:admin@example.com"> admin@example.com</a> </p> */}
                                <p className="description">I am available for freelance work. connect with me via email: <a href="mailto:adam.depth@proton.me"> adam.depth@proton.me</a> </p>
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={this.handleSubmit} >
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Your email *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Write a Subject"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Your Message"
                                        />
                                    </label>


                                    {/* <div className="rn-form-group">
                                        <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
                                    </div>  */}


                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe" >Submit</button>
                                </form>
                                <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Email Sent</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Your email has been successfully sent.</Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={this.handleCloseModal}>
                                        Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;
