import React from 'react';
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import { TextLoop } from "easy-react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
// import stellamaImage from '../assets/images/about/stellamaEdited.png';

const SlideList = [
    {
        textPosition: 'text-left',
        // category: 'Welcome to my World',
        category: '',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
const PortfolioLanding = () => {
    let title = 'About Me'
    let description = 'I am a highly motivated, tenacious, detail-oriented, and committed developer, with a get-it-done-on-time mentality. I specialize in designing, implementing, testing, and delivering complex applications across both Web2 and Web3 landscapes. \n Languages: Solidity, Javascript, Typescript, Go, Rust, HTML, CSS. \n Technologies: Smart Contracts, Foundry, Hardhat, Ethers.js, Web3.js, OpenZeppelin, Ethereum, EVM, DeFi, IPFS, Fleek, Docker, Linux/Unix, Git/GitHub, Node.js, ReactJS, Next.js, Redux, Firebase, Redis, MongoDB, AWS, Vultr, VPS, Vercel, AI, ChatGPT \n I excel in modern web development, building dynamic and secure applications with top performance. My skills cover robust front-end interfaces and scalable back-end systems. In blockchain, I thrive on innovation, continually honing my expertise with various platforms and tools to create secure and efficient decentralized systems. I\'m eager to collaborate with passionate teams to build pioneering solutions in both traditional and decentralized technologies, driving the future of the digital world.';
    const paragraphs = description.split('\n').map((paragraph, i) => <p key={i}>{paragraph}</p>);
    // const PostList = BlogContent.slice(0 , 3);
    return (
        <div className="active-dark">
            <Helmet pageTitle="Adam Bretzer - Depth Hoar"></Helmet>


            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">Hi, I’m Adam <br/>
                                            <TextLoop interval={3500} animation="just">
                                                <span> Full Stack Developer.</span>
                                                <span> Blockchain Developer.</span>
                                                <span> Solidity Developer.</span>
                                                <span> JS Developer.</span>
                                                <span> Node Validator.</span>
                                            </TextLoop>{" "}
                                            {/* update in _spacing.scss when editing textloop */}
                                            <p class="extra-break"></p> 
                                            <br/>
                                            <h2>based in Canada.</h2>
                                            </h1>
                                            {/* {value.description ?  <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start Portfolio Area */}
            <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">My Latest Projects</h2>
                                        {/* <p>As a blockchain developer, I am passionate about using the latest technologies to create decentralized solutions that empower users and disrupt traditional industries. I have experience working with a variety of blockchain platforms and am skilled in smart contract development, dApp development, and blockchain-based product design. Below are some of my latest projects, showcasing my abilities as a blockchain developer.</p> */}
                                        <p>I develop innovative solutions across both traditional Web2 and decentralized Web3 landscapes.</p>
                                        <div className="expertise-grid">
                                            <div className="expertise-section">
                                                <h5>Web2</h5>
                                                <p>Skilled in modern web development, creating dynamic and responsive applications with top-notch performance and security.</p>
                                            </div>
                                            <div className="expertise-section">
                                                <h5>Web3</h5>
                                                <p>Experienced in blockchain, specializing in smart contracts, dApps, and decentralized product design.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-6 col-md-6 col-sm-6 col-6" item="6" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        {/* <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="../assets/images/about/stellamaEdited.png" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="paragraphs">{paragraphs}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1"> 
                    <ContactThree contactImages="/assets/images/about/AdamBlackWhiteNoScare.png" contactTitle="Contact Me." /> 
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioLanding;