import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn, FaGithub  } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import FooterTwo from "../component/footer/FooterTwo";

import HeaderThree from "../component/header/HeaderThree";

const SocialShare = [
    {Social: <button className="rn-button-style--2 btn-solid"><span>Visit Application</span></button> , link: 'https://edge-apps-fork-gj1z9ls5d-edgeware-agency.vercel.app/#/delegation'},
    {Social: <button className="rn-button-style--2 btn-solid"><span>Documentation </span></button> , link: 'https://www.edgeware.wiki/edgeware-stack/governance/democracy/delegation'},
    {Social: <button className="rn-button-style--2 btn-solid"><span>Github Repo   </span><FaGithub /></button> , link: 'https://github.com/edgeware-network/apps'},
    // {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/'},
    // {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    // {Social: <FaTwitter /> , link: 'https://twitter.com/'},
]

class DelegationUI extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
            <div className="active-dark">
                <PageHelmet pageTitle='Portfolio Details' />

                {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
                <HeaderThree homeLink="/" logo="symbol-dark" color="color-dark"/>
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--35" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Delegation UI</h2>
                                    {/* <h2>Escrow Dapp</h2> */}
                                    {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h2>About</h2>
                                        <p>I am proud to present the Delegation User Interface (UI) that I built for the Edgeware blockchain on the Edgeware Apps platform. This project showcases my ability to create user-friendly and efficient interfaces for blockchain projects. The Delegation UI is an improvement on traditional vote delegation, making the process more intuitive and user-friendly. It allows users to easily see who they are actively delegating their votes to and to undelegate their votes at any time.</p>
                                        <p>As the maintainer of Edgeware Apps, which is a fork of the Polkadot Apps platform, I am dedicated to improving the user experience and contributing to the development of the Polkadot ecosystem. In line with this, I will be upstreaming this Delegation UI to the Polkadot Apps platform in the near future, to make it available for more users and communities.</p>
                                        <p>I am passionate about building tools that make it easier for people to understand and use blockchain technology. This delegation UI is an example of my commitment to creating user-friendly interfaces that simplify complex processes and make it more accessible to a broader audience. I am always seeking new opportunities to improve my skills and create high-quality interfaces for blockchain projects.</p>
                                        {/* <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Branch</span>
                                                <h4>Ability</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Types</span>
                                                <h4>Website</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Program</span>
                                                <h4>View Project</h4>
                                            </div>
                                        </div> */}

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                            <ul className="rn-lg-size d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val , i) => (
                                                    <li className="button-space" key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/delegationUI.png" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='FWj8839Y7JQ' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        
                                        {/* <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
                                        </div> */}

                                        {/* <div className="thumb">
                                            <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Related Work</span>
                                    <h2>Our More Projects</h2>
                                </div> */}
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details">
                                            <img src="/assets/images/portfolio/related-image-01.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details">Digital Analysis</a></h4>
                                        <span className="category">Technique</span>
                                    </div>
                                </div>
                            </div> */}
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details">
                                            <img src="/assets/images/portfolio/related-image-02.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details">Plan Management</a></h4>
                                        <span className="category">PLANNING</span>
                                    </div>
                                </div>
                            </div> */}
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}
                
                {/* <Footer />   */}
                <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            

            </div>
            </React.Fragment>
        )
    }
}
export default DelegationUI;
