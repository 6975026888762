// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Dark Home Layout 
// import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import EscrowDapp from "./elements/EscrowDapp.js";
import OrderbookDex from "./elements/OrderbookDex.js";
import EdgewareDocumentation from "./elements/EdgewareDocumentation.js";
import DelegationUI from "./elements/DelegationUI.js";
import EdgewareRosetta from "./elements/EdgewareRossetta.js";
import EcommercesStore from "./elements/EcommercesStore.js";
import Error404 from "./elements/error404";


// Blocks Layout

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component {
    render() {
      return (
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<DarkPortfolioLanding />} />
  
              {/* Element Layout */}
              <Route path="/escrow-dapp" element={<EscrowDapp />} />
              <Route path="/orderbook-dex" element={<OrderbookDex />} />
              <Route path="/edgeware-documentation" element={<EdgewareDocumentation />} />
              <Route path="/delegation-ui" element={<DelegationUI />} />
              <Route path="/edg-rossetta-api" element={<EdgewareRosetta />} />
              <Route path="/ecommerces-store" element={<EcommercesStore />} />
              <Route path="/404" element={<Error404 />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
        </BrowserRouter>
      );
    }
  }

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();