import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabTwo extends Component{
    render(){
        let 
        tab1 = "Web2",
        tab2 = "Web3",
        tab3 = "Experience";
        // tab2 = "Awards",
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        {/* <Tab>{tab4}</Tab> */}
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    Skilled in modern web development, creating dynamic and responsive applications with top-notch performance and security. Proficient in front-end technologies like ReactJS, Next.js, and Redux, as well as back-end systems using Node.js, MongoDB, Redis, and Firebase. Experienced in deploying and managing applications with Docker, AWS, Vultr, and VPS.
                                                </li>
                                                {/* <li>
                                                    <a href="/service">Front-end Development<span> - Development</span></a>
                                                    Websites, web experiences, user-friendly dApps with a responsive design.
                                                </li> */}
                                                {/* <li>
                                                    <a href="/service">Interaction design <span> - Animation</span></a>
                                                    I like to move it move it.
                                                </li> */}
                                            </ul>
                                        </div>
                                    </TabPanel>



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                    Proficient in designing, writing, and deploying secure smart contracts using Solidity, Hardhat, and OpenZeppelin. Experienced with integrating blockchain technologies using Ethers.js, Web3.js, and various blockchain platforms. Skilled in creating efficient and decentralized applications (dApps) with a focus on security and performance.
                                               </li>
                                               {/* <li>
                                                   <a href="/service">DeFi 201<span> - Moralis Academy</span></a>
                                               </li>
                                               <li>
                                                   <a href="/service">Ethereum Smart Contract Programming 101<span> - Moralis Academy</span></a>
                                               </li>
                                               <li>
                                                   <a href="/service">Ethereum 101<span> - Moralis Academy</span></a>
                                               </li>
                                               <li>
                                                   <a href="/service">Blockchain & Bitcoin 101<span> - Moralis Academy</span></a>
                                               </li>
                                               <li>
                                                   <a href="/service">Bachelors Degree <span> - Mount Royal University, Calgary, AB</span></a>
                                               </li> */}
                                               {/* <li>
                                                   <a href="/service">Bachelors Degree <span> - Mount Royal University, Calgary, AB</span></a> 2007
                                               </li> */}
                                           </ul>
                                       </div>
                                    </TabPanel>


                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Full Stack Blockchain Developer<span> - Depth Ltd</span></a> 2023 - Present
                                               </li>
                                               <li>
                                                   <a href="/service">Full Stack Blockchain Developer<span> - Edgeware</span></a> 2021 - 2023
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                    {/* <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Awwwards.com <span>- Winner</span></a> 2019 - 2020
                                               </li>
                                               <li>
                                                   <a href="/service">CSS Design Awards <span>- Winner</span></a> 2017 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Design nominees <span>- site of the day</span></a> 2013- 2014
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel> */}

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabTwo;