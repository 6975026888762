import React, { Component } from "react";
import {Link} from "react-router-dom";

// TODO add the links for other pages
const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Web3',
        title: 'Escrow Dapp',
        link: '/escrow-dapp'
    },
    {
        image: 'image-2',
        category: 'Web3',
        title: 'Orderbook DEX',
        link: '/orderbook-dex'
    },
    {
        image: 'image-6',
        category: 'Web2',
        title: 'Ecommerce Store',
        link: '/ecommerces-store'
    },
    {
        image: 'image-4',
        category: 'Web3',
        title: 'Delegation UI',
        link: '/delegation-ui'
    },
    {
        image: 'image-3',
        category: 'Web2',
        title: 'Edgeware Documentation',
        link: '/edgeware-documentation'
    },
    {
        image: 'image-5',
        category: 'Web3',
        title: 'Edgeware Rossetta API integration',
        link: '/edg-rossetta-api'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        // console.log(column, 'column');
        return (
            <React.Fragment>
                {list.map((value, index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className={`thumbnail-inner ${value.category === 'Web3' ? 'web3-project' : 'web2-project'}`}>
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={value.link}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={`${value.link}`}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={value.link}></Link>
                        </div>
                    </div>
                ))}
            </React.Fragment>
        );
    }
}

export default PortfolioList;
